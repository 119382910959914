import { init } from "next-firebase-auth";

const credential = process.env.GOOGLE_APPLICATION_CREDENTIALS
  ? JSON.parse(
      Buffer.from(
        process.env.GOOGLE_APPLICATION_CREDENTIALS!,
        "base64"
      ).toString()
    )
  : null;

export default function () {
  init({
    authPageURL: "/",
    appPageURL: "/missions/1",
    loginAPIEndpoint: "/api/auth/login",
    logoutAPIEndpoint: "/api/auth/logout",
    firebaseAdminInitConfig: {
      credential,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL!,
    },
    firebaseClientInitConfig: {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY!,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN!,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL!,
    },
    cookies: {
      name: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
      keys: [process.env.COOKIE_SECRET],
      httpOnly: true,
      maxAge: 14 * 60 * 60 * 24 * 1000,
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure:
        process.env.NODE_ENV === "production" &&
        process.env.NEXT_PUBLIC_APP_ENV !== "test",
      signed: true,
    },
  });
}
