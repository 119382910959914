import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";

import initNextFirebaseAuth from "~/lib/initNextFirebaseAuth";
import "~/lib/firebase";
import "~/styles/app.scss";

initNextFirebaseAuth();

const App = ({ Component, pageProps }: AppProps) => {
  return <Component {...pageProps} />;
};

export default appWithTranslation(App);
